<template>
  <div class="home">
    <top></top>
  <div class="center">
    <div class="nav-wrap">
     
     <div class="resultTop">
       <div class="left">
          <h2>支付结果</h2>
       </div>
       <div class="right">
          <div class="gimage">
            <img class="img" src="../../assets/index/hong.png" alt="" />
            尾易优选
          </div>
         <h2>上海宝钢集团旗舰店</h2>
       </div>
     </div>
     
     <div class="resultCenter">
       <!-- 等待 -->
       <div class="state await"  v-if="wait">
         <div class="top">
           <img src="../../assets/order/await.png" alt="">
           <span >获取支付结果大约20秒，请耐心等待...</span>
         </div>
         <div class="bootm">
             <el-button type="danger">查看详情</el-button>
         </div>
       </div>
       <!-- 成功 -->
         <div class="state finish" v-if="finish">
         <div class="top">
           <img src="../../assets/order/finish.png" alt="">
           <span >支付成功</span>
         </div>
         <div class="bootm">
             <el-button type="danger">查看详情</el-button>
         </div>
       </div>
       <!-- 失败 -->
             <div class="state fail" v-if="fail">
         <div class="top">
           <img src="../../assets/order/fail.png" alt="">
           <span>支付失败    原因：取消支付 </span>
         </div>
         <div class="bootm">
             <el-button type="danger">查看详情</el-button>
         </div>
       </div>
    
     </div>
   
  
    </div>
  </div>
    <Foo></Foo>
     
  </div>
</template>

<script>
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
export default {
  data(){
    return{
      wait:true,
      finish:false,
      fail:false
    }
  },
 
  components: {
    // HelloWorld
    Top,
    Foo,

 
  },
  created(){
   
  },
  methods:{
      
     
  },
  mounted(){
   
   

  }
}
</script>
<style lang="scss" scoped>
.home{
  min-width: 1100px;
}
.center{
    width: 100%;
    height:706px;
}
.nav-wrap{
  // border: 1px solid red;
  height: 100%;
  width: 60%;
  margin: auto;
  h2{
    color: rgb(233,79,85);
    font-size: 20px;
    font-weight: 600;
  }
  .resultTop{
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
        border-bottom: 1px solid #ccc;
      .right{
        display: flex;
         .gimage {
      width: 80px;
      height: 32px;
      font-weight: 500;
      color: #e94f55;
      font-size: 14px;
      // margin-left: 30px;
      overflow: hidden;
      // border: 1px solid red;
      margin-right: 30px;
      .img {
        position: relative;
        top: 3px;
      }
    }
      }
  }
  .resultCenter{
    margin: 100px 0;
    .state{
      width: 400px; 
      margin: 0 auto;
      .top{
        display: flex;
        align-items: center;
        img{
          vertical-align: middle;
          width: 50px;
          height: 50px;
                  }
                  span{
                    font-size: 18px;
                    font-weight: 600;
                    margin-left: 20px;
                    color: rgb(70, 68, 68);
                  }
      }
      .bootm{
      
         margin: 0 auto;
         width: 150px;
        margin-top: 40px;
        .el-button{
          width: 150px;
       background-color: rgb(233,79,85);
        }
      }
    }
.fail{
  width: 300px;
}
.finish{
width: 160px;
}
  }
}




</style>
